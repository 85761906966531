import React from 'react';
import whiteLogo from './shortfinsoftware_stacked_white_nobg.png';
// import logo from './logo.svg';
//import whiteLogo from '/public/Images/shortfinsoftware_stacked_white_nobg.png';
import './App.css';

function App() 
{
  	return (
		<div className="App">
			<header className="App-header">
			</header>
			<body className="App-body">
				{/* <img alt="main_white_logo" height="500"  src={process.env.PUBLIC_URL + '/images/shortfinsoftware_stacked_white_nobg.png'} /> */}
				<img id="home_main_logo" src={whiteLogo} alt="White_Main_Logo" />
				<br/>
				<h3>Custom Software Solutions</h3>
			</body>
			<footer>&copy; Copyright 2019 Shortfin Software LLC</footer>
		</div>
	);
}

export default App;
